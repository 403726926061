import React, { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Etapa1 from "@/images/new/jornadas/1a-etapa.svg"
import Etapa2 from "@/images/new/jornadas/2a-etapa.svg"
import Etapa3 from "@/images/new/jornadas/3a-etapa.svg"
import AOS from "aos"
import "aos/dist/aos.css"

const JornadaSapientia = () => {
  useEffect(() => {
		AOS.init({
			duration : 2000
		});
	}, []);
  return (
    <section className="jornada-sapientia">
      <Container>
        <Row data-aos="zoom-in" data-aos-once="true">
          <Col lg={12} md={12} sm={12}>
            <h2 className="title-w text-center" style={{ marginBottom: 50, lineHeight: '56px', fontSize: '40px' }}>¿Cuál es el formato de este proyecto?</h2>
          </Col>
        </Row>
        <Row className="fases-individuais" data-aos="zoom-in" data-aos-once="true">
          <Col lg={4} md={4} sm={12} >
            <div className="text-center MB-72">
              <img src={Etapa1} alt='' />
              <h4>Una presentación</h4>
              <p>Una presentación de 10 slides que contiene todos los puntos de un plan de negocios efectivo;</p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} >
            <div className="text-center MB-72">
              <img src={Etapa2} alt='' />
              <h4>Un video</h4>
              <p>Un video, de hasta 3 minutos, que cuenta la importancia de este proyecto en sus vidas.</p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} >
            <div className="text-center">
              <img src={Etapa3} alt='' />
              <h4>Los 5 mejores </h4>
              <p>Los 5 mejores luego se unen a los anteproyectos de estudiantes brasileños.</p>
            </div>
          </Col>
        </Row>
        <Row data-aos="zoom-in" data-aos-once="true">
          <Col lg={12} md={12} sm={12} >
            <div style={{ width: '100%', maxWidth: 860, margin: 'auto' }}>
							<h5 className="sub-jornada text-center" style={{ marginTop: 72, marginBottom: 32 }}>Serán desarrollados y competirán por <span style={{ color: "#AC88FD" }} >10 lugares</span> entre los Proyectos que serán presentados presencialmente en Foz del Iguazú.</h5>
							<h5 className="sub-jornada text-center" style={{ marginBottom: 0 }}>Hasta el final, los proyectos y los estudiantes pasan por una serie de tutorías y comentarios para que estén aún más inspirados y comprometidos.</h5>
						</div>
					</Col>
        </Row>
      </Container>
    </section>
  );
};

export default JornadaSapientia;

