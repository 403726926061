import React, { Fragment, useEffect } from "react"
import FormatoDelProyecto from '@/components/FormatoDelProyecto'
import LineaDeTiempo from '@/components/lineaDeTiempo'
import AOS from "aos"
import "aos/dist/aos.css"
import Logo from '@/images/new/landingPageEs/logo.svg'
import Img1 from '@/images/new/landingPageEs/header.png'
import Objetivos from '@/images/new/landingPageEs/objetivos.png'
import Img3 from '@/images/new/landingPageEs/participante.png'
import Participante2 from '@/images/new/landingPageEs/participante-2.png'
import Dot from '@/images/new/landingPageEs/dot.svg'
import Sapientia from '@/images/new/landingPageEs/sapientia.svg'
import Vertere from '@/images/new/landingPageEs/vertere.svg'
import Fractal from '@/images/new/landingPageEs/fractal.svg'

// function onChange(value) {
//   console.log("Captcha value:", value);
// }

const Finalistas = ({}) => {
	useEffect(() => {
		AOS.init({
			duration : 2000
		});
	}, []);
  return (
    <Fragment>
			<section className="pageLanding" style={{ background: 'linear-gradient(79.8deg, #02F2F2 -0.55%, #028BF2 11.49%, #026FC2 21.97%, #3501AB 77.48%, #5D16FE 94.24%, #7C16FE 100%)', paddingBottom: 150, paddingTop: 46 }}>
        <div className="container">
					<div className="row" data-aos="fade-right" data-aos-once="true">
						<img src={Logo} className="MR-M-16" />
					</div>
          <div className="row">
            <div className="col-lg-12 d-none-img-mob">
              <img src={Img1} style={{ marginBottom: 32 }} />
            </div>
            <div className="col-lg-5 col-sm-12" data-aos="fade-right" data-aos-once="true">
              <div className="banner_content PT-32">
                <div className="title-header-es">La Olimpiada del Futuro dan un paso adelante y llega a Latinoamérica</div>
                <div className="p-header-es">Sapientia existe para ti, el estudiante, para que inventes el conocimiento que quieres aprender.</div>
								<div className="register-btn" style={{ marginTop: 52 }}>
                  <a href='https://forms.gle/ziaY7HrEDbb9EodF7' target='_blank' className="inscreva-se_btn" style={{ padding: '20px 35px', fontWeight: 600 }}>¡Envíe su proyecto aquí!</a>
								</div>
							</div>
            </div>
            <div className="col-lg-7 d-none-img-desk" data-aos="fade-left" data-aos-once="true">
              <img className="img-size" src={Img1} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </section>
			<section style={{ background: '#FFFFFF', paddingTop: '88px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-none-img-mob">
              <img className="img-size" src={Objetivos} style={{ marginBottom: 32 }} />
            </div>
            <div className="col-lg-7" data-aos="fade-right" data-aos-once="true">
              <p className="land-2-paragraph" style={{ marginBottom: 32, fontSize: 32, fontWeight: 'bold', lineHeight: '120%' }}>Sapientia se llama la Olimpiada del Futuro porque nos inspiramos en un documento firmado por casi 200 países en las Naciones Unidas: la Agenda 2030.</p>
              <p className="land-2-paragraph" style={{ marginBottom: 32 }}>Agenda es compromiso. En nuestro caso, el compromiso más importante está en los llamados 17 Objetivos del Desarrollo Sostenible, porque es ahí donde se describen las exigencias que desafían la calidad de vida de las personas y el futuro del planeta.</p>
              <p className="land-2-paragraph" style={{ marginBottom: 0 }}>Pues bien, Sapientia nace para ayudar a que esos Objetivos cobren vida: la Agenda 2030 se lanzó en 2015; tres años después... Nació Sapientia.</p>
            </div>
            <div className="col-lg-5 d-none-img-desk" data-aos="fade-left" data-aos-once="true">
              <img className="img-size" src={Objetivos} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </section>
			<section style={{ background: '#FFFFFF', paddingTop: 32 }}>
        <div className="container">
          <div className="row">
						<div className="col-lg-5 col-sm-12 d-none-img-desk" data-aos="fade-right" data-aos-once="true">
              <img className="img-size" src={Img3} style={{ marginBottom: '-21px', width: '100%' }} />
            </div>
            <div className="col-lg-7 col-sm-12" data-aos="fade-left" data-aos-once="true">
              <p className="land-2-paragraph" style={{ marginBottom: 32, fontSize: 32, fontWeight: 'bold', lineHeight: '120%' }}>ODS 4: Educación de Calidad</p>
              <p className="land-2-paragraph" style={{ marginBottom: 32 }}>En el torbellino de novedades de la Educación, las escuelas buscan sus propias formas originales de responder a las exigencias del nuevo milenio y, sobre todo, de conectar y comunicarse con los jóvenes.</p>
              <p className="land-2-paragraph" style={{ marginBottom: 0 }}>Surge entonces la pregunta: ¿no es ésta una oportunidad ideal para escuchar las ideas de estos alumnos?</p>
              <p className="land-2-paragraph" style={{ marginBottom: 32 }}>La Olimpiada del Futuro tratará exclusivamente sobre la Educación de Calidad - ODS 4 de la Agenda 2030. A lo largo del año, presentaremos decenas de proyectos relevantes realizados en el mundo y celebraremos debates entre los participantes.</p>
              <p className="land-2-paragraph" style={{ marginBottom: 0, fontWeight: 'bold' }}>Es la ocasión perfecta para conectar a estudiantes y escuelas en la innovación en Educación.</p>
            </div>
						<div className="col-lg-5 col-sm-12 d-none-img-mob">
              <img className="img-size" src={Img3} style={{ marginBottom: '-16px', width: '100%', marginTop: 16 }} />
            </div>
          </div>
        </div>
      </section>
			<section style={{ background: '#026FC2', padding: '72px 0' }} data-aos="fade-up" data-aos-once="true">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center ">
							<p className="cta-2-paragraph">"Sapientia existe para ti, el estudiante, para que inventes el conocimiento que quieres aprender."</p>
              <p className="cta-3-paragraph" style={{ marginBottom: 0 }}>Únete al futuro. ¡Saca tu mate!, ¡apunta-te a Sapientia!</p>
            </div>
          </div>
        </div>
      </section>
			<section style={{ background: '#FFFFFF', paddingTop: '88px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-7" style={{ marginBottom: 90 }} data-aos="fade-right" data-aos-once="true">
							<h2 className="heading-section">¿Cómo aplicar?</h2>
              <p className="land-2-paragraph" style={{ marginBottom: 32 }}>La inscripción es gratuita: ¡totalmente gratis! Y se puede hacer a través del correo electrónico</p>
              <a href='https://forms.gle/ziaY7HrEDbb9EodF7' target='_blank' className="inscreva-se_btn" style={{ padding: '20px 35px', fontWeight: 600 }}>¡Envíe su proyecto aquí!</a>
							<h2 className="heading-section" style={{ marginTop: 42 }}>¿Es una prueba presencial?</h2>
							<p className="land-2-paragraph" style={{ marginBottom: 32 }}>Las Olimpiadas tienen lugar en un entorno digital. La presentación de los finalistas se realiza de forma presencial sólo en la final, al final de cada año.</p>
              <h2 className="heading-section">Pero, ¿Cómo funciona entonces?</h2>
							<p className="land-2-paragraph" style={{ marginBottom: 32 }}><img src={Dot} alt='' style={{ marginRight: 12 }} />Armas un equipo en tu escuela.</p>
							<p className="land-2-paragraph" style={{ marginBottom: 32 }}><img src={Dot} alt='' style={{ marginRight: 12 }} />Junto con profesores y colegas, discuta cómo resolver un problema en su escuela o en otra escuela de la región, utilizando la Agenda 2030 de la ONU y centrándose en el ODS-4.</p>
							<p className="land-2-paragraph" style={{ marginBottom: 0 }}><img src={Dot} alt='' style={{ marginRight: 12 }} />Tu equipo armará una especie de plan de negocios, un anteproyecto autoral que utiliza tus conocimientos para resolver problemas reales de tu vida diaria.</p>
            </div>
            <div className="col-lg-5" style={{ display: 'flex', alignItems: 'flex-end' }} data-aos="fade-left" data-aos-once="true">
              <div>
								<img className="img-size" src={Participante2} style={{ width: '100%' }} />
							</div>
            </div>
          </div>
        </div>
      </section>
    	<FormatoDelProyecto />
			<LineaDeTiempo />
			<section style={{ background: '#FFFFFF', paddingBottom: 102 }} data-aos="zoom-in-down" data-aos-once="true">
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
						<div className="col-lg-2 col-sm-12 text-center MB-M-48">
							<a href="/"><img src={Sapientia} /></a>
						</div>
						<div className="col-lg-2 col-sm-12 text-center MB-M-48">
							<p className="footer-p-es">Organização:</p>
							<a href='https://institutovertere.org/' target='_blank'><img src={Vertere} /></a>
						</div>
						<div className="col-lg-2 col-sm-12 text-center">
							<p className="footer-p-es">Apoio:</p>
							<a href='https://fractaltecnologia.com.br/' target='_blank'><img src={Fractal} /></a>
						</div>
					</div>
				</div>
			</section>
    </Fragment>
  );
};

export default Finalistas;
