import React, { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import AOS from "aos"
import "aos/dist/aos.css"
import Item1 from "@/images/new/landingPageEs/calendario.png"
import Item2 from "@/images/new/landingPageEs/autofalante.png"
import Item3 from "@/images/new/landingPageEs/microfone.png"

const JornadaSapientia = () => {
	useEffect(() => {
		AOS.init({
			duration : 2000
		});
	}, []);
  return (
    <section className="linea-de-tiempo">
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12} data-aos="fade-right" data-aos-once="true">
            <h2 className="heading-section" style={{ marginBottom: 50 }}>Línea de tiempo</h2>
          </Col>
        </Row>
        <Row className="etapas-linea" data-aos="zoom-in" data-aos-once="true">
          <Col lg={4} md={4} sm={12} >
            <div className="text-center MB-72">
              <img src={Item1} alt='' style={{ marginBottom: '-110px' }} />
              <div className="box-tempo">
								<h4>15 de septiembre</h4>
								<p style={{ paddingBottom: 42, height: 90, marginBottom: 0 }}>Fecha de entrega del proyecto</p>
							</div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} >
						<div className="text-center MB-72">
              <img src={Item2} alt='' style={{ marginBottom: '-110px' }} />
              <div className="box-tempo">
								<h4>30 de septiembre</h4>
								<p style={{ paddingBottom: 42, height: 90, marginBottom: 0 }}>Resultado</p>
							</div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} >
						<div className="text-center">
              <img src={Item3} alt='' style={{ marginBottom: '-110px' }} />
              <div className="box-tempo">
								<h4>4 y 5 de noviembre</h4>
								<p style={{ paddingBottom: 42, height: 90, marginBottom: 0 }}>Final presencial en Foz del Iguazú</p>
							</div>
            </div>
          </Col>
        </Row>
				<Row>
					<Col lg={12} md={12} sm={12}>
						<div className="line-border-4" style={{ marginTop: 54 }} />
						<div style={{ margin: '32px 0' }} data-aos="fade-right" data-aos-once="true">
							<h3 className="faq-title">Registra tu escuela en Sapientia - Olimpiada del Futuro, vía email</h3>
							<a href="mailto:contato@olimpiadadofuturo.com.br" className="faq-link">contato@olimpiadadofuturo.com.br</a>
						</div>
						<div className="line-border-4" />
						<div style={{ margin: '32px 0' }} data-aos="fade-right" data-aos-once="true">
							<h3 className="faq-title">¿Hay alguna manera de averiguar sobre proyectos anteriores?</h3>
							<p className="faq-p">Los proyectos brasileños que fueron finalistas de <br />Sapientia se pueden encontrar en:</p>
							<a href='/finalistas' className="faq-link">olimpiadadofuturo.com.br/finalistas</a>
						</div>
						<div className="line-border-4" />
						<div style={{ margin: '32px 0' }} data-aos="fade-right" data-aos-once="true">
							<h3 className="faq-title">¿Quieres saber más sobre nuestro proyecto?</h3>
							<a href='/' className="faq-link">olimpiadadofuturo.com.br</a>
						</div>
						<div className="line-border-4" />
					</Col>
				</Row>
      </Container>
    </section>
  );
};

export default JornadaSapientia;

